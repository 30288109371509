@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.head2 {
    color: #cc3333;
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    text-align: center;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
}
.tnclist li {
    list-style: circle;
    line-height: 200%;
    color: #666;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
}


.faq-content .panel-heading {
    background: #f3f3f3 !important;
    color: #666666;
}

    .faq-content .panel-heading a {
        text-decoration: none;
    }

.faq-content #accordion .panel-title > a.accordion-toggle.collapsed::before, .faq-content #accordion a.collapsed[data-toggle="collapse"]::before {
    content: "+";
    color: #000;
    font-size: 21px;
    font-weight: bolder;
}
.faq-content .p-accordion .p-accordion-header .p-accordion-header-link{
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 500;
    background: #f3f3f3 !important;
    color: #666666;
}
.faq-content .p-accordion .p-accordion-content{
    padding: 10px 15px !important;
    font-size: 14px;
    font-weight: 500;
}
.faq-content .p-accordion .p-accordion-content p{
    margin: 2px;
}
.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link{
    background: #f3f3f3 !important;
    color: #666666;
}
.icon-add {
    width: 15px;
    margin-right: 8px;
}
.icon-subtract{
    width: 15px;
    margin-right: 8px;
}